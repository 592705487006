import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {AuthService} from "./auth.service";
import {environment} from '../../environments/environment';
import {Auth} from "@angular/fire/auth";
import {Database, get, ref} from "@angular/fire/database";
import {getFunctions, httpsCallable} from "@angular/fire/functions";
import {ToastService} from "./toast.service";

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  // private baseUrl: string = "https://guesic-develop-default-rtdb.europe-west1.firebasedatabase.app"
  private baseUrl: string = environment.firebaseConfig.databaseURL;
  private _auth: Auth = inject(Auth);
  private _db: Database = inject(Database);
  private functions = getFunctions();

  constructor(private http: HttpClient, private _authService: AuthService, private _toastService: ToastService) {
  }

  public GetSong(gameType: string | null, songId: string | null): Observable<any> {
    let url: string;
    let auth = '?auth=' + this._authService.aUser.accessToken;
    if (gameType == null) {
      url = `${this.baseUrl}/games/regularNL/songs/${songId}.json${auth}`; // Construct the URL
    } else {
      url = `${this.baseUrl}/games/${gameType}/songs/${songId}.json${auth}`; // Construct the URL
    }
    return this.http.get<any>(url);
  }

  public async findUserByEmail(email: string): Promise<any> {
    try {
      const uid = await this.getUidByEmail(email);
      return this.getUserRole(uid);
    } catch (error) {
      this._toastService.showError('Error finding user by email');
      throw error;
    }
  }

  public async getUserRole(uid: string): Promise<any> {
    const userRoleRef = ref(this._db, `/users/${uid}/role`);
    try {
      const snapshot = await get(userRoleRef);
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        this._toastService.showError('No data available');
        return null;
      }
    } catch (error) {
      this._toastService.showError('Error getting user role:');
      throw error;
    }
  }

  async setUserRole(role: string): Promise<any> {
    const setUserRoleFn = httpsCallable(this.functions, 'addUserRole');
    try {
      return await setUserRoleFn({role});
    } catch (error) {
      console.error('Error calling addUserRole function:', error);
      throw error;
    }
  }

  async getUidByEmail(email: string): Promise<string> {
    const getUidByEmailFn = httpsCallable(this.functions, 'getUidByEmail');
    try {
      const result: any = await getUidByEmailFn({email});
      return result.data.uid;
    } catch (error) {
      console.error('Error calling getUidByEmail function:', error);
      throw error;
    }
  }
}
