import {Component} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {QrScannerComponent} from "../qrscanner/qrscanner.component";
import {NavbarComponent} from "../navbar/navbar.component";
import {ComponentBase} from "../../models/component-base";
import {SpotifyComponent} from "../spotify/spotify.component";
import {NgClass, NgForOf, NgIf, NgStyle} from "@angular/common";
import * as XLSX from 'xlsx';
import {Router} from "@angular/router";
import {ISong} from "../../models/ISong";
import {ColorPickerModule} from "ngx-color-picker";
import {MatSidenavModule} from "@angular/material/sidenav";
import {SidebarModule} from "primeng/sidebar";
import {DropdownModule} from "primeng/dropdown";
import {FirebaseService} from "../../services/firebase.service";
import {ToastService} from "../../services/toast.service";
import {ProgressBarModule} from "primeng/progressbar";

@Component({
  selector: 'admin',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, QrScannerComponent, NavbarComponent, SpotifyComponent, NgIf,
    NgForOf, NgStyle, ColorPickerModule, MatSidenavModule, SidebarModule, NgClass, DropdownModule, ProgressBarModule],
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent extends ComponentBase {

  public FileName: string = "No file chosen";
  public SongsFromFile: ISong[] = [];
  public colorList: string[] = ["#FFC556", "#F56110"];
  public roles: string[] = ["user", "admin"];
  public selectedRole: string = "";
  public userRole: string = "";
  public email: string = "";
  private lastKnownEmail = "";

  public isLoading = false;

  public isSidebarVisible: boolean = true;
  constructor(private _router: Router, private _firebaseService: FirebaseService, private _toastService: ToastService) {
    super()
  }

  protected override OnInit(): void {}

  public OnFileChange(ev: Event) {
    const target = ev.target as HTMLInputElement;
    const files = target.files;

    if (files) {
      this.FileName = files[0].name;
    } else {
      this.FileName = 'No file chosen';
    }

    let workBook: any = null;
    let jsonData = null;
    if (!files || files.length === 0) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = (event.target as FileReader).result;
      workBook = XLSX.read(data, {type: 'binary'});
      jsonData = workBook.SheetNames.reduce((initial: any, name: any) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      this.SongsFromFile = this.ExtractData(jsonData);
    };
    reader.readAsBinaryString(files[0]);
  }

  public ExtractData(data: any): ISong[] {
    const firstSheetName = Object.keys(data)[0];
    const rows = data[firstSheetName];
    return rows.map((row: any) => ({
      id: row.ID,
      title: row.Titel,
      artist: row.Artiest,
      year: row.Jaartal
    }));
  }

  public GoToHome() {
    this._router.navigate(['/']);
  }

  public GetBackgroundColor(index: number): string {
    const totalSongs = this.SongsFromFile.length;
    return this.getMultiStepGradientColor(this.colorList, totalSongs, index);
  }

  public hexToRgb(hex: string): { r: number; g: number; b: number } {
    let r = 0, g = 0, b = 0;
    // 3 digits
    if (hex.length == 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length == 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return { r, g, b };
  }

  public rgbToHex(r: number, g: number, b: number): string {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
  }

  public getMultiStepGradientColor(colors: string[], totalSteps: number, step: number): string {
    const totalSegments = colors.length - 1;
    const segmentLength = totalSteps / totalSegments;
    let segmentIndex = Math.min(Math.floor(step / segmentLength), totalSegments - 1);

    let localStep = step - (segmentLength * segmentIndex);
    let stepRatio = localStep / segmentLength;

    const startColor = colors[segmentIndex];
    const endColor = colors[segmentIndex + 1];

    const startRGB = this.hexToRgb(startColor);
    const endRGB = this.hexToRgb(endColor);
    let r = Math.round(startRGB.r + (endRGB.r - startRGB.r) * stepRatio);
    let g = Math.round(startRGB.g + (endRGB.g - startRGB.g) * stepRatio);
    let b = Math.round(startRGB.b + (endRGB.b - startRGB.b) * stepRatio);

    return this.rgbToHex(r, g, b);
  }

  public addNewColor() {
    this.colorList.push(this.colorList[this.colorList.length - 1]);
  }

  public removeColor(index: number) {
    if (this.colorList.length > 2) {
      this.colorList.splice(index, 1)
    }
  }

  public identify(index: any, item: any) {
    return item.name;
  }

  public onSearchUser() {
    if (this.email && this.email.includes('@') && this.email.includes('.') && this.lastKnownEmail != this.email) {
      this.isLoading = true;
      this.lastKnownEmail = this.email;
      this._firebaseService.findUserByEmail(this.email)
        .then(role => {
          this.isLoading = false;
          if (role) {
            this.selectedRole = role;
            this.userRole = role;
          } else {
            this._toastService.showError('User not found');
          }
        })
        .catch(error => this.isLoading = false);
    }
  }

  public onRoleChange(newValue: string) {
    this.selectedRole = newValue;
  }

  public updateUserRole() {
    this._firebaseService.setUserRole(this.selectedRole).then(response => {
      this._toastService.showSuccess(response.data.result)
    }).catch(error => {console.log(error)})
  }
}
