export const environment = {
  production: false,
  redirectUri: 'https://test.guesic.com/',
  firebaseConfig: {
    apiKey: "AIzaSyBeUiXoiNF3AkvkjLUBh4NGz0gwHJClMnQ",
    authDomain: "guesic-test.firebaseapp.com",
    databaseURL: "https://guesic-test-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "guesic-test",
    storageBucket: "guesic-test.appspot.com",
    messagingSenderId: "758945644157",
    appId: "1:758945644157:web:de091278fb373af8153b14",
    measurementId: "G-8R9N951V4N"
  }
};
